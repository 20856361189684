import { ReactElement, useEffect, useState } from 'react';

import { useRouteLoaderData } from 'react-router-dom';

import { InfoIcon } from '../../../../../icons/InfoIcon';
import { Input } from '../../../../../Input';
import { useHirePlanForm } from '../../../../../../contexts/HirePlanFormProvider';
import { Select } from '../../../../../Select';
import { PaymentBilletForm } from './BilletForm';
import { format } from 'date-fns';

export function PaymentDebitForm(): ReactElement {
  const data: any = useRouteLoaderData('token');

  interface Operation {
    Nome: string;
    Id: string;
  }

  const operation: Operation[] = [
    { Nome: 'Conta corrente -- 001', Id: '001' },
    { Nome: 'Conta poupança -- 013', Id: '013' },
  ];

  const renameBanco = () => {
    data.bancos.map(banco => {
      banco.Nome = banco.nm_banco;
      banco.Id = banco.id_banco;
    });
  };
  renameBanco();
  const { register, errors, watch, setValue } = useHirePlanForm();

  const firstPaymentBillet = watch('payment.firstPaymentBillet');

  return (
    <div className="max-[1000px]:flex-col max-[1000px]:items-center min-[748px]:mt-6 min-[748px]:p-6 min-[748px]:pt-4 w-full flex flex-col justify-center">
      <span className="text-primary-main text-xl font-medium mb-8">
        Dados da conta
      </span>
      <div className="grid min-[748px]:grid-cols-1">
        <div className="grid grid-cols-1 gap-6">
          <div className="grid grid-cols-3 gap-8">
            <Select
              label="Banco"
              options={[]}
              optionsFull={data.bancos}
              placeholder="Banco"
              errorMessage={errors.payment?.bank?.message}
              {...register('payment.bank')}
            />
            <Input
              label="Agência"
              className="w-full"
              placeholder="00"
              errorMessage={errors.payment?.agency?.message}
              {...register('payment.agency')}
            />
            <Input
              label="Conta corrente"
              className="w-full"
              placeholder="000 000-0"
              errorMessage={errors.payment?.debitAcount?.message}
              {...register('payment.debitAcount')}
            />
          </div>
          <div className="grid grid-cols-3 gap-8">
            <Select
              label="Operação"
              options={[]}
              optionsFull={operation}
              placeholder="Operação"
              errorMessage={errors.payment?.operation?.message}
              {...register('payment.operation')}
            />
            {!firstPaymentBillet && (
              <Select
                label="Dia vencimento"
                options={data.vencimentoDebito.map(boleto =>
                  format(new Date(boleto.select), 'dd/MM/yyyy'),
                )}
                placeholder="Dia vencimento"
                errorMessage={errors.payment?.debitDate?.message}
                {...register('payment.debitDate')}
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex items-start border bg-primary-container2 rounded-md w-full py-6 px-4 mt-6">
        {/* <InfoIcon className="flex-none items-center min-w-[30px]" /> */}
        <div className="grid grid-cols-1 gap-8">
          <Input
            className="w-[20px] h-[20px]"
            label=""
            type="checkbox"
            errorMessage={errors.payment?.firstPaymentBillet?.message}
            {...register('payment.firstPaymentBillet')}
          />
        </div>
        {/* <input
          type="checkbox"
          className="w-full placeholder:text-medium-emphasis px-4 py-3 rounded-lg text-high-emphasis border focus:ring-1 focus:outline-none"
        ></input> */}
        <span className="flex-1 text-neutral-dark text-sm ml-4">
          <span className="font-bold block">
            Quero pagar a primeira no boleto.
          </span>
          Selecionando essa opção, seu plano ficará disponível assim que a
          OdontoGroup reconhecer o pagamento do seu boleto. As outras
          mensalidades serão cobradas normalmente na conta cadastrada. O boleto
          será enviado para seu email após o envio desse formulário.
        </span>
      </div>
      {firstPaymentBillet && <PaymentBilletForm />}
    </div>
  );
}
